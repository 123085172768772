import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_PUBLIC_API_KEY;
export function getOrdersByFloorId(floorId: string) {
    return axios.get<any>(`${API_URL}/pantry/v1/orders/floor/${floorId}/`);
}
export function getOrdersByZoneId(zoneId: string) {
    return axios.get<any>(`${API_URL}/pantry/v1/orders/zone/${zoneId}/`, {
        headers: {
            "Authorization": `Bearer ${API_KEY}`,
            'x-api-key': true
        },
    });
}
export function getStatusList() {
    return axios.get<any>(`${API_URL}/pantry/v1/orders/status/`);
}
export function updateOrderStatus(orderId: string, data) {
    return axios.put<any>(`${API_URL}/pantry/v1/orders/${orderId}/`, data);
}
export function getItemsByFloorId(floorId: string, skip: number, limit: number) {
    return axios.get<any>(`${API_URL}/pantry/v1/items/floor/${floorId}/?skip=${skip}&limit=${limit}`, {
        headers: {
            "Authorization": `Bearer ${API_KEY}`,
            'x-api-key': true
        },
    });
}
export function createOrder(data: any) {
    return axios.post<any>(`${API_URL}/pantry/v1/orders/`, data, {
        headers: {
            "Authorization": `Bearer ${API_KEY}`,
            'x-api-key': true
        },
    });
}