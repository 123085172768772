/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartTable } from "../../../sdb/layout/components/smart-table/smart-table";
import { PageTitle } from "../../../sdb/layout/core";

export function Customers() {
  const history = useNavigate();
  const smartTableRef: any = useRef(null);
  const [showModal, setModalView] = useState(false);
  const [selectedRecords, setActiveRecords] = useState({});
  const columnDef = [
    {
      name: "Id",
      dataKey: "_id",
      className: "min-w-150px",
      display: "text",
    },
    {
      name: "Name",
      dataKey: "name",
      className: "min-w-150px",
      display: "text",
    },
    {
      name: "Address",
      dataKey: "address",
      className: "min-w-150px",
      display: "text",
    },
    {
      name: "Contact",
      dataKey: "contact",
      className: "min-w-150px",
      display: "text",
    },
    {
      name: "Status",
      dataKey: "is_active",
      className: "min-w-90px",
      display: "status",
    },
    {
      display: "action",
      name: "Action",
      dataKey: "action",
      className: "min-w-90px",
      actions: [
        { label: "Access" },
        { label: "Edit" },
        { label: "Delete" },
        { label: "Licenses" },
      ],
    },
  ];

  const actionCallBack = (data: any) => {
    if (data.action === "add") {
      history("/customers/add");
    } else if (data.action === "edit") {
      const row = data.rowData.item;
      history(`/customers/${row._id}/manage`, { state: row });
    } else if (data.action === "access") {
      const row = data.rowData.item;
      history(`/customers/${row._id}/access`, { state: row });
    } else if (data.action === "Licenses") {
      const row = data.rowData.item;
      history(`/customers/${row._id}/licenses`, { state: row });
    }
  };
  return (
    <>
      <div className="d-flex flex-wrap flex-stack mb-6">
        <PageTitle breadcrumbs={[]}>Customers</PageTitle>
      </div>
      <div className="row">
        <SmartTable
          ref={smartTableRef}
          getURL="/customers/"
          deleteUrl="/customers/delete/"
          bulkdelete="/customers/delete/bulk/"
          serachPlaceholder="Serach"
          addBtnText="Add New Customer"
          imagePathForNoRecord="/media/location.png"
          noRecordHelpText="Click on the below button to add your customer"
          actionCallBack={actionCallBack}
          cols={columnDef}
          multiSelectRequired={true}
        />
      </div>
    </>
  );
}
