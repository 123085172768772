import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
// location methods
export function addCustomer(data: any) {
  return axios.post<any>(`${API_URL}/customers/create/`, data);
}
export function updateCustomer(data: any, id: number) {
  return axios.put<any>(`${API_URL}/customers/update/${id}/`, data);
}
export function getEndpoints() {
  return axios.get<any>(`${API_URL}/endpoints/`);
}

export function addUsergroup(data: any) {
  return axios.post<any>(`${API_URL}/user-group/create/`, data);
}

export function updateUsergroup(data: any, id: number) {
  return axios.put<any>(`${API_URL}/user-group/update/${id}/`, data);
}

export function getsingleUsergroup(id: number) {
  return axios.get<any>(`${API_URL}/user-group/customer_id/${id}/`);
}

export function enableModulesAccess(data: any, id: any) {
  return axios.put<any>(`${API_URL}/modules/access/${id}/`, data);
}
export function addCustomerAdmin(data: any) {
  return axios.post<any>(`${API_URL}/users/create/`, data);
}
export function updateCustomerAdmin(email: any) {
  return axios.put<any>(`${API_URL}/users/update/admin/${email}/`);
}
export function deleteCustomerAdmin(email: any) {
  return axios.delete<any>(`${API_URL}/users/delete/admin/${email}/`);
}
