/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { KTIcon } from "../../../../sdb/helpers";
import { useLocation } from "react-router-dom";
import {
  getCustomersList,
  updateModule,
  getModuleById,
  deleteAccessModuleMapping,
} from "./requests";
import Select from "react-select";
import Swal from "sweetalert2";

const ViewModules = () => {
  const [activeView, setActiveView] = useState("Information");
  const location: any = useLocation();
  const [modulesList, setModulesList] = useState([]);
  const [filterCustomersList, setfilterCustomersList] = useState([]);
  const [moduleInfo, setModuleInfo]: any = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  let modulesData = location.state;

  const getModulesData = async () => {
    try {
      const { data } = await getModuleById(modulesData._id);
      if (data) {
        setModulesList(data.customers);
        setModuleInfo(data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const handleSelectChange = (selectedOptions) => {
    setSelectedIds(selectedOptions.map((option) => option.value));
  };
  const deleteRecord = async (item: any, rowIndex: number) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        /* const deleteItemUrl = `${props.deleteUrl}${item._id}/`;
        deleteAction(deleteItemUrl)
          .then((r) => {
            let newRecords = [...allRecords];
            const nr = newRecords.filter((ditem: any) => ditem._id != item);
            setAllRecords(nr);
            setFilterCategoryList(nr);
            getRecords();
          })
          .catch((error: any) => {
            Swal.fire("Deleted!", error?.response?.data?.detail, "error");
          }); */
        deleteAccessModuleMapping(item._id, moduleInfo._id)
          .then((r) => {
            getModulesData();
          })
          .catch((error: any) => {
            Swal.fire("Deleted!", error?.response?.data?.detail, "error");
          });
      }
    });
  };
  const handleUpdateClick = async () => {
    try {
      let data = { customers: selectedIds };

      updateModule(data, modulesData._id)
        .then((daa: any) => {
          getModulesData();
          getCustomersData();
          Swal.fire("Module", "Customer mapped with this module!", "success");
        })
        .catch((error: any) => {
          Swal.fire(
            "Update module customer!",
            error.response.data.detail,
            "error"
          );
        });
    } catch (err) {
      console.log(err, "err");
    }
  };
  const getCustomersData = async () => {
    try {
      const { data } = await getCustomersList();
      if (data) {
        const filteredCustomers = data.filter((dataCustomer) => {
          return !modulesData.customers.some(
            (moduleCustomer) => moduleCustomer.name === dataCustomer.name
          );
        });

        filteredCustomers.forEach((element) => {
          element["value"] = element._id;
          element["label"] = element.name;
        });
        setfilterCustomersList(filteredCustomers);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  useEffect(() => {
    getModulesData();
    getCustomersData();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h2 className="fw-bolder my-2">Modules</h2>
          <p>Modules</p>
          <div className="d-flex overflow-auto h-55px">
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
              <li className="nav-item">
                <a
                  onClick={() => setActiveView("Information")}
                  className={
                    `nav-link text-active-primary me-6 c-p ` +
                    (activeView === "Information" && "active")
                  }
                >
                  Information
                </a>
              </li>

              <li className="nav-item">
                <a
                  onClick={() => setActiveView("Access")}
                  className={
                    `nav-link text-active-primary me-6 c-p ` +
                    (activeView === "Access" && "active")
                  }
                >
                  Access
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div>
                <h5>Information</h5>
                <hr></hr>
                <div className="row">
                  <div className="col-sm-3">
                    <dt>Module Name</dt>
                  </div>
                  <div className="col-sm-9">
                    <dd>{modulesData.name}</dd>
                  </div>
                </div>
                <div className="row">
                  <dt className="col-sm-3">Version</dt>
                  <dd className="col-sm-9">{modulesData.meta[0].version}</dd>
                </div>
                <div className="row">
                  <dt className="col-sm-3">Author</dt>
                  <dd className="col-sm-9">{modulesData.meta[0].author}</dd>
                </div>
                <div className="row">
                  <dt className="col-sm-3">Support</dt>
                  <dd className="col-sm-9">{modulesData.meta[0].support}</dd>
                </div>
              </div>
            </div>
          </div>

          {activeView === "Access" && (
            <>
              <div className="my-10">
                <div className="d-flex justify-content-start mt-7">
                  <div className="col-4">
                    <Select
                      isMulti
                      name="module_id"
                      options={filterCustomersList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleSelectChange}
                    />
                  </div>
                  <button
                    className="btn btn-primary"
                    style={{ marginLeft: "12px" }}
                    onClick={handleUpdateClick}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="card-body py-4">
                    <div className="table-responsive">
                      <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead>
                          <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                            <th>Id</th>
                            <th>Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="text-gray-600 fw-bold">
                          {modulesList.map((item: any, rowIndex: number) => {
                            return (
                              <tr role="row" key={rowIndex}>
                                <td>{item._id}</td>
                                <td>{item.name}</td>
                                <td>
                                  <a
                                    className="btn btn-light btn-active-light-primary btn-sm"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                    id="dropdownMenuButton1"
                                  >
                                    Actions
                                    <KTIcon
                                      iconName="down"
                                      className="fs-5 m-0"
                                    />
                                  </a>
                                  <div
                                    className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
                                    data-kt-menu="flase"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <div className="menu-item px-3">
                                      <a
                                        className="menu-link px-3"
                                        data-kt-users-table-filter="delete_row"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          deleteRecord(item, rowIndex);
                                        }}
                                      >
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewModules;
