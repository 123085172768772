/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartTable } from "../../../sdb/layout/components/smart-table/smart-table";

export function Permissions() {
  const history = useNavigate();
  const smartTableRef: any = useRef(null);
  const [showModal, setModalView] = useState(false);
  const [selectedRecords, setActiveRecords] = useState({});
  const [tableConfig, setTableConfig] = useState({});
  const columnDef = [
    {
      name: "User Role",
      dataKey: "user_role",
      className: "min-w-150px",
      display: "text",
    },
    {
      name: "Id",
      dataKey: "_id",
      className: "min-w-150px",
      display: "text",
    },

    {
      display: "action",
      name: "Action",
      dataKey: "action",
      className: "min-w-90px",
      actions: [{ label: "View" }],
    },
  ];

  const actionCallBack = (data: any) => {
    if (data.action === "add") {
      history("/permissions/list");
    } /* else if (data.action === "edit") {
      const row = data.rowData.item;
      history(`/permissions/${row._id}/manage`, { state: row });
    } */ else if (data.action === "view") {
      const row = data.rowData.item;
      history(`/permissions/${row._id}/view`, { state: row });
    }
  };
  return (
    <>
      <div className="row">
        <SmartTable
          ref={smartTableRef}
          getURL="/permissions/"
          // deleteUrl="/permission/delete/"
          hideActions={true}
          serachPlaceholder="Serach"
          addBtnText="Add New permission"
          imagePathForNoRecord="/media/location.png"
          noRecordHelpText="Click on the below button to add your permission"
          actionCallBack={actionCallBack}
          cols={columnDef}
          multiSelectRequired={true}
        />
      </div>
    </>
  );
}
