import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const getZonesURL = `${API_URL}/zones/`;
export function getZones() {
    return axios.get<any>(getZonesURL);
  }
// location methods
export function getCategoriesList() {
    return axios.get<any>(`${API_URL}/pantry/v1/categories/`);
}
export function getCategoriesListByCustomer(cId: any) {
    return axios.get<any>(`${API_URL}/pantry/v1/categories/customer/${cId}/`);
}
export function deleteCategory(id: string) {
    return axios.delete<any>(`${API_URL}/pantry/v1/categories/${id}/`);
}
export function getFloorList() {
    return axios.get<any>(`${API_URL}/floors/`);
}
export function addNewCategory(data: any) {
    return axios.post<any>(`${API_URL}/pantry/v1/categories/`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}
export function updateCategory(data: any, id: string) {
    return axios.put<any>(`${API_URL}/pantry/v1/categories/${id}/`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}
export function addNewItem(data: any) {
    return axios.post<any>(`${API_URL}/pantry/v1/items/`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}
export function updateItem(data: any, id: string) {
    return axios.put<any>(`${API_URL}/pantry/v1/items/${id}/`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}
export function getItemList(start,limit) {
    return axios.get<any>(`${API_URL}/pantry/v1/items/?skip=${start}&limit=${limit}`);
}
export function deleteItem(id: string) {
    return axios.delete<any>(`${API_URL}/pantry/v1/items/${id}/`);
}