import { useEffect, useState } from "react";
import {
  getHostServiceData,
  addSVisitor,
  getSetupDataByCustomer,
} from "./request";

import { useAuth } from "../auth";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import Select from "react-select";

export function AddVisitorInfo(props) {
  const [hostList, setHostList] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [isUserLoading, setLoading] = useState(false);
  const { currentUser, logout } = useAuth();
  const [preChecked, setPreChecked] = useState(false);
  const [customer_id, setCustomerId] = useState(
    currentUser.customer_id
      ? currentUser.customer_id
      : "64bd1e8214063a88c701285b" // "64bd1e8214063a88c701285b" //
  );
  const [location_id, setLocationId] = useState(
    currentUser.location_id?.[0]
      ? currentUser.location_id?.[0]
      : "64be5f5e750cf62596e44133" //"64bd2223a87d3e5d644d6b0d" //
  );
  const [visitorData] = useState<any>({
    full_name: "",
    email: "",
    phone_number: "",
    company_name: "",
    host_id: "",
    customer_id: currentUser ? currentUser.customer_id : "",
    visiting_date_time: "",
    end_date_time: "",
    pre_approved: "",
    duration: "",
  });
  const [setupData, setSetupData] = useState(null);
  const [durationValue, setDurationValue] = useState("");

  const getHostData = async () => {
    try {
      const { data } = await getHostServiceData(customer_id, location_id);
      if (data) {
        const hostData = data.filter((item) => item.role === "user");
        setHostList(hostData);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getSetupData = async () => {
    try {
      const { data } = await getSetupDataByCustomer(customer_id);
      if (data) {
        if (data && data.duration) {
          const durationList = data.duration.split(",");
          setDurationList(durationList);
        }
        setSetupData(data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const editUserSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
    full_name: Yup.string().required("Name is required"),
    phone_number: Yup.string().required("Mobile Number is required"),
    company_name: Yup.string().required("Company Name is required"),
    visiting_date_time: Yup.string().required("Date is required"),
    // end_date_time: Yup.string().required("Date is required"),
    host_id: Yup.object({
      value: Yup.string().required("Host type is required"),
    }),
    duration: Yup.object({
      value: Yup.string().required("duration is required"),
    }),
  });
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
    }
  };

  const checks = (e) => {
    if (e.target.checked == true) {
      setPreChecked(true);
    } else {
      setPreChecked(false);
    }
  };

  const formik: any = useFormik({
    initialValues: visitorData,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const params = {
        full_name: values.full_name,
        email: values.email,
        phone_number: values.phone_number,
        company_name: values.company_name,
        host_id: values.host_id.value,
        duration: values.duration.value,
        visiting_date_time: values.visiting_date_time,
        end_date_time: values.end_date_time,
        pre_approved: preChecked,
      };
      const param = {
        full_name: values.full_name,
        email: values.email,
        phone_number: values.phone_number,
        company_name: values.company_name,
        host_id: values.host_id.value,
        duration: values.duration.value,
        visiting_date_time: values.visiting_date_time,
        pre_approved: preChecked,
      };
      try {
        //await createUser(values)
        /*  visitorData.full_name = values.full_name;
        visitorData.email = values.email;
        visitorData.phone_number = values.phone_number;
        visitorData.company_name = values.company_name;
        visitorData.host_id = values.host_id.value;
        visitorData.duration = values.duration.value;
        visitorData.visiting_date_time = values.visiting_date_time;
        visitorData.end_date_time = values.end_date_time;
        visitorData.pre_approved = preChecked; */
        addSVisitor(values.end_date_time ? params : param)
          .then((daa: any) => {
            props.modalCallBack(true);
          })
          .catch((error: any) => {
            Swal.fire("Visitor Info!", error.response?.data?.detail, "error");
          });
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(true);
        cancel(true);
      }
    },
  });
  useEffect(() => {
    getHostData();
    getSetupData();
  }, []);
  return (
    <>
      <div
        className="d-flex flex-column scroll-y me-n7 pe-7"
        id="kt_modal_add_user_scroll"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-max-height="auto"
        data-kt-scroll-dependencies="#kt_modal_add_user_header"
        data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
        data-kt-scroll-offset="300px"
      >
        <div className="row mb-7">
          <form
            id="kt_modal_add_user_form"
            className="form"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className="col-12 mb-3">
              <label className="required fw-bold fs-6 mb-2">Full Name</label>
              <input
                placeholder="Name"
                {...formik.getFieldProps("full_name")}
                type="text"
                name="full_name"
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0",
                  {
                    "is-invalid":
                      formik.touched.full_name && formik.errors.full_name,
                  },
                  {
                    "is-valid":
                      formik.touched.full_name && !formik.errors.full_name,
                  }
                )}
                autoComplete="off"
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.full_name && formik.errors.full_name && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.full_name}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 mb-3">
              <label className="required fw-bold fs-6 mb-2">Email</label>
              <input
                placeholder="Email"
                {...formik.getFieldProps("email")}
                type="text"
                name="email"
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0",
                  {
                    "is-invalid": formik.touched.email && formik.errors.email,
                  },
                  {
                    "is-valid": formik.touched.email && !formik.errors.email,
                  }
                )}
                autoComplete="off"
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-6 mb-3">
                <label className="required fw-bold fs-6 mb-2">Mobile</label>
                <input
                  placeholder="Mobile"
                  {...formik.getFieldProps("phone_number")}
                  type="text"
                  name="phone_number"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid":
                        formik.touched.phone_number &&
                        formik.errors.phone_number,
                    },
                    {
                      "is-valid":
                        formik.touched.phone_number &&
                        !formik.errors.phone_number,
                    }
                  )}
                  autoComplete="off"
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.phone_number && formik.errors.phone_number && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.phone_number}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-6 mb-3">
                <div className="d-flex justify-content-center pt-10">
                  <input
                    className="form-check-input me-4"
                    type="checkbox"
                    data-kt-check={preChecked}
                    data-kt-check-target="#kt_table_users .form-check-input"
                    checked={preChecked}
                    onChange={(e) => checks(e)}
                  />
                  <label className="fw-bold fs-6 ">Is Pre-Approved</label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-3">
              <label className="required fw-bold fs-6 mb-2">Company Name</label>
              <input
                placeholder="Company Name"
                {...formik.getFieldProps("company_name")}
                type="text"
                name="company_name"
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0",
                  {
                    "is-invalid":
                      formik.touched.company_name && formik.errors.company_name,
                  },
                  {
                    "is-valid":
                      formik.touched.company_name &&
                      !formik.errors.company_name,
                  }
                )}
                autoComplete="off"
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.company_name && formik.errors.company_name && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.company_name}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 mb-3">
              <label className="required fw-bold fs-6 mb-2">Host Id</label>
              <Select
                {...formik.getFieldProps("host_id")}
                name="host_id"
                options={hostList.map((item: any) => ({
                  value: item._id,
                  label: `${item.first_name} ${item.last_name}`,
                }))}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => {
                  formik.setFieldValue("host_id", e);
                }}
                onFocus={(e) => {
                  formik.setFieldTouched("host_id", true);
                }}
              />
              {formik.touched.host_id && formik.errors.host_id && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.host_id.value}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 mb-3">
              <label className="required fw-bold fs-6 mb-2">Duration</label>
              <Select
                {...formik.getFieldProps("duration")}
                name="duration"
                options={durationList.map((item: any, i) => ({
                  value: item,
                  label: item,
                }))}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e: any) => {
                  formik.setFieldValue("duration", e);
                  setDurationValue(e.value);
                }}
                onFocus={(e) => {
                  formik.setFieldTouched("duration", true);
                }}
              />
              {formik.touched.duration && formik.errors.duration && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.duration.value}</span>
                  </div>
                </div>
              )}
            </div>
            {durationValue !== "Custom" ? (
              <div className="col-12 mb-3">
                <label className="required fw-bold fs-6 mb-2">
                  Visiting Date and Time
                </label>
                <input
                  placeholder="Select date"
                  type="datetime-local"
                  onFocus={(e: any) => {
                    e.target.showPicker();
                  }}
                  name="visiting_date_time"
                  {...formik.getFieldProps("visiting_date_time")}
                  autoComplete="off"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid":
                        formik.touched.visiting_date_time &&
                        formik.errors.visiting_date_time,
                    }
                  )}
                />
                {formik.touched.visiting_date_time &&
                  formik.errors.visiting_date_time && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">
                          {formik.errors.visiting_date_time}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            ) : (
              <div className="d-flex">
                <div className="col-6 mb-3 me-4">
                  <label className="required fw-bold fs-6 mb-2">
                    Visiting Date and Time
                  </label>
                  <input
                    placeholder="Select date"
                    type="datetime-local"
                    onFocus={(e: any) => {
                      e.target.showPicker();
                    }}
                    name="visiting_date_time"
                    {...formik.getFieldProps("visiting_date_time")}
                    autoComplete="off"
                    className={clsx(
                      "form-control form-control-solid mb-3 mb-lg-0",
                      {
                        "is-invalid":
                          formik.touched.visiting_date_time &&
                          formik.errors.visiting_date_time,
                      }
                    )}
                  />
                  {formik.touched.visiting_date_time &&
                    formik.errors.visiting_date_time && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {formik.errors.visiting_date_time}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
                <div className="col-6 mb-3">
                  <label className="required fw-bold fs-6 mb-2">
                    Visiting End Date and Time
                  </label>
                  <input
                    placeholder="Select date"
                    type="datetime-local"
                    onFocus={(e: any) => {
                      e.target.showPicker();
                    }}
                    name="end_date_time"
                    {...formik.getFieldProps("end_date_time")}
                    autoComplete="off"
                    /*  className={clsx(
                      "form-control form-control-solid mb-3 mb-lg-0",
                      {
                        "is-invalid":
                          formik.touched.end_date_time &&
                          formik.errors.end_date_time,
                      }
                    )} */
                    className="form-control form-control-solid mb-3 mb-lg-0"
                  />
                  {/*  {formik.touched.end_date_time &&
                    formik.errors.end_date_time && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {formik.errors.end_date_time}
                          </span>
                        </div>
                      </div>
                    )} */}
                </div>
              </div>
            )}

            <div className="text-center pt-15">
              <button
                type="reset"
                onClick={() => {
                  props.modalCallBack(true);
                  props.model_ref.current?.classList.remove("main-div");
                }}
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                disabled={formik.isSubmitting || isUserLoading}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={
                  isUserLoading ||
                  formik.isSubmitting ||
                  !formik.isValid ||
                  !formik.touched
                }
              >
                <span className="indicator-label">Save</span>
                {(formik.isSubmitting || isUserLoading) && (
                  <span className="indicator-progress">
                    Please wait...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
