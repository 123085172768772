import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../../sdb/helpers";
import { User, UsersQueryResponse } from "./_models";
const API_URL = process.env.REACT_APP_API_URL;
const USER_URL = `${API_URL}/users/create/`;
const UPDATE_USER_URL = `${API_URL}/users/update/`;
const GET_USERS_URL = `${API_URL}/users/`;

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => {
      return d.data;
    });
};

const getUserById = (id: ID): Promise<any | undefined> => {
  return axios
    .get(`${API_URL}/users/${id}/`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => {
      return response;
    });
};

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data);
};

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(`${UPDATE_USER_URL}${user.id}/`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data);
};

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/users/delete/${userId}/`);
};

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`));
  return axios.all(requests).then(() => {});
};
const deleteAdminUser = (email: string): Promise<void> => {
  return axios.delete(`${API_URL}/users/delete/email/${email}/`);
};

export {
  getUsers,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
  deleteAdminUser,
};
