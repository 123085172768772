import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { addNewItem, updateItem, getCategoriesList, getFloorList, getCategoriesListByCustomer } from "./requests";
import Swal from "sweetalert2";
const API_URL = process.env.REACT_APP_API_URL;

export function ItemAdd() {
  const history = useNavigate();
  const location = useLocation();
  const itemInfo: any = location.state;
  const [categoryList, setCategoryList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const { currentUser, logout } = useAuth();
  const [imageFile, setImageFile]: any = useState(null)
  const [imageFileUrl, setImageFileurl]: any = useState(itemInfo ? `${API_URL}/${itemInfo.profile_pic}` : null)
  const editUserSchema = Yup.object().shape({
    is_active: Yup.object({
      value: Yup.string().required("Status is required"),
    }),
    description: Yup.string().required("Description is required"),
    name: Yup.string().required("Name is required"),
    floor_id: Yup.array(
      Yup.object({
        value: Yup.string().required(),
      })
    ),
    category_id: Yup.object({
      value: Yup.string().required("Category is required"),
    })

  });
  const [categoryData] = useState<any>({
    name: itemInfo ? itemInfo.name : '',
    description: itemInfo ? itemInfo.description : '',
    category_id: '',
    floor_id: '',
    is_active: itemInfo?.is_active === false ? { value: '0', label: 'Inactive' } : { value: '1', label: 'Active' },
    customer_id: currentUser.customer_id
  });
  const formik: any = useFormik({
    initialValues: categoryData,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      let formData = new FormData();
      if (imageFile) {
        formData.append("profile_pic", imageFile);
      }
      formData.append("customer_id", currentUser.customer_id);
      const isActive = values.is_active.value == "0" ? 'false' : 'true'
      formData.append("is_active", isActive);
      formData.append("description", values.description);
      formData.append("name", values.name);
      formData.append('floor_id', values.floor_id.map((item)=>item.value))
      formData.append('category_id', values.category_id.value)
      try {
        if (itemInfo) {
          updateItem(formData, itemInfo._id)
            .then((daa: any) => {
              Swal.fire(
                "Category Item",
                "Category Item has been updated succefully!",
                "success"
              );
              history('/modules/pantry-management', { state: { view: 'item' } });
            })
            .catch((error: any) => {
              Swal.fire(
                "Add Category Item!",
                error.response.data.detail,
                "error"
              );
            });
        }
        else {
          addNewItem(formData)
            .then((daa: any) => {
              Swal.fire(
                "Category Item",
                "Category Item has been saved succefully!",
                "success"
              );
              history('/modules/pantry-management', { state: { view: 'item' } });
            })
            .catch((error: any) => {
              Swal.fire(
                "Add Category Item!",
                error.response.data.detail,
                "error"
              );
            });
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(true);
      }
    },
  });
  const getCategory = async () => {
    try {
      const { data } = currentUser.role === 'admin' ? await getCategoriesListByCustomer(currentUser.customer_id) : await getCategoriesList();
      if (data) {
        data.forEach((item: any) => {
          item['value'] = item._id;
          item['label'] = item.name;
        })
        setCategoryList(data);
        if (itemInfo) {
          const selectedCategory = data.find((d) => d.value === itemInfo.category_id);
          formik.setFieldValue("category_id", selectedCategory);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getFloors = async () => {
    try {
      const { data } = await getFloorList();
      if (data) {
        data.forEach((item: any) => {
          item['value'] = item._id;
          item['label'] = item.name;
        })
        setFloorList(data);
        if (itemInfo) {
          const selectedFloor = data.filter((d) => itemInfo.floor_id.includes(d.value));
          formik.setFieldValue("floor_id", selectedFloor);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getCategory();
    getFloors();
  }, []);
  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-flex flex-stack"
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
              Pantry Management
            </h1>

            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a onClick={(e) => {
                  e.preventDefault();
                  history('/modules');
                }} className="text-muted text-hover-primary">
                  Modules
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px"></span>
              </li>
              <li className="breadcrumb-item text-muted">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    history('/modules/pantry-management', { state: { view: 'item' } });
                  }}
                  className="text-muted text-hover-primary"
                >
                  Pantry Management
                </a>
              </li>

              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px"></span>
              </li>
              <li className="breadcrumb-item text-muted">{itemInfo ? 'Edit' : 'Add'} Item</li>
            </ul>
          </div>
        </div>
      </div>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-xxl"
        >
          <form
            id="kt_ecommerce_add_product_form"
            className="form d-flex flex-column flex-lg-row mb-10"
            onSubmit={formik.handleSubmit}
          >
            <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
              <div className="card card-flush py-4">
                <div className="card-header">
                  <div className="card-title">
                    <h2>Item Thumbnail</h2>
                  </div>
                </div>
                <div className="card-body text-center pt-0">
                  <div
                    className="image-input image-input-empty image-input-outline image-input-placeholder mb-3"
                    data-kt-image-input="true"
                  >
                    <div
                      className="image-input-wrapper w-150px h-150px"
                      style={{
                        backgroundImage: `${imageFileUrl ? `url(${imageFileUrl})` : "url('/media/svg/files/blank-image.svg')"}`,
                      }}
                    ></div>
                    <label
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      title="Change avatar"
                    >
                      <i className="ki-duotone ki-pencil fs-7">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <input
                        type="file"
                        name="avatar"
                        onChange={(e) => {
                          const files = e.target.files
                          if (files && files.length > 0) {
                            const file = files[0];
                            let reader = new FileReader();
                            const url = URL.createObjectURL(file);
                            setImageFileurl(url);
                            setImageFile(file)
                          }
                        }}
                        accept=".png, .jpg, .jpeg"
                      />
                      <input type="hidden" name="avatar_remove" />
                    </label>
                    <span
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="cancel"
                      data-bs-toggle="tooltip"
                      title="Cancel avatar"
                    >
                      <i className="ki-duotone ki-cross fs-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </span>
                    <span
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="remove"
                      data-bs-toggle="tooltip"
                      title="Remove avatar"
                    >
                      <i className="ki-duotone ki-cross fs-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </span>
                  </div>
                  <div className="text-muted fs-7">
                    Set the item thumbnail image. Only *.png, *.jpg and *.jpeg
                    image files are accepted
                  </div>
                </div>
              </div>
              <div className="card card-flush py-4">
                <div className="card-header">
                  <div className="card-title">
                    <h2>Status</h2>
                  </div>
                  <div className="card-toolbar">
                    <div
                      className={formik.values.is_active.value === '0' ? 'rounded-circle bg-danger w-15px h-15px' : 'rounded-circle bg-success w-15px h-15px'}
                      id="kt_ecommerce_add_product_status"
                    ></div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <Select
                    {...formik.getFieldProps("is_active")}
                    name="is_active"
                    options={[
                      { value: '1', label: 'Active' },
                      { value: '0', label: 'Inactive' }
                    ]}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => {
                      formik.setFieldValue("is_active", e);
                    }}
                  />
                  <div className="text-muted fs-7">Set the item status.</div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
              <div className="d-flex flex-column gap-7 gap-lg-10">
                <div className="card card-flush py-4">
                  <div className="card-header">
                    <div className="card-title">
                      <h2>Item Setup</h2>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <div className="mb-10 fv-row">
                      <label className="required form-label">Item Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control mb-2"
                        placeholder="Item name"
                        {...formik.getFieldProps("name")}
                      />

                      <div className="text-muted fs-7">
                        A item name is required and recommended to be unique.
                      </div>
                    </div>

                    <div className="mb-10 fv-row">
                      <div className="row row-cols-md-2">
                        <div className="col">
                          <label className="required form-label">
                            Category
                          </label>
                          <Select
                            {...formik.getFieldProps("category_id")}
                            name="category_id"
                            options={categoryList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              formik.setFieldValue("category_id", e);
                            }}
                          />
                        </div>
                        <div className="col">
                          <label className="required form-label">Floor</label>
                          <Select
                            {...formik.getFieldProps("floor_id")}
                            name="floor_id"
                            isMulti={true}
                            options={floorList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              formik.setFieldValue("floor_id", e);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <label className="form-label required">Description</label>
                      <textarea
                        placeholder="Description"
                        rows={6}
                        className="form-control mb-2"
                        name="description"
                        autoComplete="off"
                        {...formik.getFieldProps("description")}
                      />

                      <div className="text-muted fs-7">
                        Set a description to the item for better visibility.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    history('/modules/pantry-management', { state: { view: 'item' } });
                  }}
                  id="kt_ecommerce_add_product_cancel"
                  className="btn btn-light me-5"
                >
                  Cancel
                </a>

                <button
                  type="submit"
                  id="kt_ecommerce_add_product_submit"
                  className="btn btn-primary" disabled={
                    formik.isSubmitting ||
                    !formik.isValid || (!imageFile && !itemInfo)
                  }
                >
                  <span className="indicator-label">Save Changes</span>
                  <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
