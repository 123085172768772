import axios from "axios";
import { AuthModel, UserModel } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;

//export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`;
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/users/me/`;
export const LOGIN_URL = `${API_URL}/auth/login/`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password/`;
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset-password/`;

// Server should return AuthModel
export function login(email: string, password: string) {
  const params = {
    username: email,
    password,
    grant_type: "password",
    client_id: "",
    client_secret: "",
    scope: "",
  };
  return axios.post<AuthModel>(LOGIN_URL, params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

export function requestPassword(email: string) {
  const url = `${REQUEST_PASSWORD_URL}${email}/`;
  // Set maxRedirects to 0 to prevent Axios from following redirects
  return axios.post<{ result: boolean }>(url, null, {
    maxRedirects: 0,
  });
}

export function resetPassword(password: string, token: any) {
  return axios.post<{ result: boolean }>(`${RESET_PASSWORD_URL}${token}/`, {
    password,
  });
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      //Authorization: `Bearer ${token}`, // Include the authorization token in the header
    },
  });
}
