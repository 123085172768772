/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useAuth } from "../../auth";
import IonRangeSlider from "react-ion-slider";
import { addAlert, updateAlert, getAlertByCustomerId } from "./request";

export function AlertSetup() {
  const [valueFrom, setValueFrom] = useState(0);
  const [valueTo, setValueTo] = useState(0);
  const { currentUser } = useAuth();
  const [alertId, setAlertID] = useState("");
  const [range, setRange] = useState([
    {
      // id: 1,
      name: "Good",
      from_value: 0,
      to_value: 0,
    },
    {
      // id: 2,
      name: "Moderate",
      from_value: 0,
      to_value: 0,
    },
    {
      // id: 3,
      name: "Unhealthy if sensitive",
      from_value: 0,
      to_value: 0,
    },
    {
      // id: 4,
      name: "Unhealthy",
      from_value: 0,
      to_value: 0,
    },
    {
      // id: 5,
      name: "Very unhealthy",
      from_value: 0,
      to_value: 0,
    },
    {
      // id: 6,
      name: "Hazardous",
      from_value: 0,
      to_value: 0,
    },
  ]);

  const handleChange = (data, index) => {
    const newRange = [...range];
    newRange[index].from_value = data.from;
    newRange[index].to_value = data.to;
    setRange(newRange);
  };

  const getAlertList = async () => {
    try {
      const { data } = await getAlertByCustomerId(currentUser.customer_id);
      if (data) {
        setRange(data[0].data);
        setAlertID(data[0]._id);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const addAlertList = async () => {
    try {
      const params = {
        customer_id: currentUser.customer_id,
        data: range,
        meta: {},
      };
      await addAlert(params);
    } catch (err) {
      console.log(err, "err");
    }
  };

  const updateAlertList = async () => {
    try {
      const params = {
        customer_id: currentUser.customer_id,
        data: range,
        meta: {},
      };
      await updateAlert(params, alertId);
    } catch (err) {
      console.log(err, "err");
    }
  };

  useEffect(() => {
    getAlertList();
  }, []);

  useEffect(() => {
    range?.forEach((item: any) => {
      setValueFrom(item.from_value);
      setValueTo(item.to_value);
    });
  }, [range]);

  return (
    <div className="current-range m-b">
      <div className="">
        {range?.map((item, index) => {
          return (
            <>
              <div
                className="d-flex flex-row align-items-center mt-4"
                key={item.name}
              >
                <div className="col-3">{item.name}</div>
                <div className="col-9">
                  <IonRangeSlider
                    type={"double"}
                    min={0}
                    max={500}
                    from={item.from_value}
                    to={item.to_value}
                    step={1}
                    grid={true}
                    gridNum={20}
                    onFinish={(data) => handleChange(data, index)}
                  />
                </div>
              </div>
            </>
          );
        })}
      </div>
      <div className="d-flex justify-content-end mt-7 ">
        {valueFrom == 0 && valueTo == 0 ? (
          <button
            className="btn btn-primary me-4"
            style={{ marginLeft: "12px" }}
            onClick={() => addAlertList()}
          >
            Save
          </button>
        ) : (
          <button
            className="btn btn-primary me-4"
            style={{ marginLeft: "12px" }}
            onClick={() => updateAlertList()}
          >
            Update
          </button>
        )}

        <button
          className="btn btn-secondary "
          // onClick={() => clear()}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
