/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { SmartTable } from "../../../../sdb/layout/components/smart-table/smart-table";

export function ModuelsPage() {
  const smartTableRef: any = useRef(null);
  const { currentUser } = useAuth();
  const history = useNavigate();
  const columnDef = [
    {
      name: "Id",
      dataKey: "_id",
      className: "min-w-150px",
      display: "text",
    },
    {
      name: "Name",
      dataKey: "name",
      className: "min-w-150px",
      display: "text",
    },
    {
      name: "Alise Name",
      dataKey: "meta",
      className: "min-w-150px",
      display: "arrayObject",
      objectKey: "alise",
    },
    {
      name: "Version",
      dataKey: "meta",
      className: "min-w-150px",
      display: "arrayObject",
      objectKey: "version",
    },
    {
      display: "action",
      name: "Action",
      dataKey: "action",
      className: "min-w-90px",
      actions: [{ label: "View" }],
    },
  ];

  const actionCallBack = (data: any) => {
    if (data.action === "add") {
      history("/modules/add");
    } else if (data.action === "view") {
      const row = data?.rowData?.item;
      history(`/modules/${row?._id}/view`, { state: row });
    }
  };

  return (
    <>
      {currentUser.role === "sa" ? (
        <div className="row">
          <div className="d-flex flex-wrap flex-stack mb-6">
            <h3 className="fw-bolder my-2">Modules</h3>
          </div>
          <SmartTable
            ref={smartTableRef}
            hideActions={true}
            getURL="/modules/"
            // deleteUrl="/modles/delete/"
            serachPlaceholder="Serach"
            addBtnText="Add"
            imagePathForNoRecord="/media/location.png"
            noRecordHelpText="Click on the below button to add your Modules"
            actionCallBack={actionCallBack}
            cols={columnDef}
            multiSelectRequired={true}
          />
        </div>
      ) : (
        <>
          {" "}
          <div className="d-flex flex-wrap flex-stack mb-6">
            <h3 className="fw-bolder my-2">Modules</h3>
          </div>
          <div className="d-flex flex-wrap ">
            {/* <div className="row modules"> */}
            {currentUser?.module?.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  {item.meta[0].alise === "VMS" && (
                    <div
                      className="me-5 c-p"
                      onClick={() => {
                        history("/modules/visitor-management?id=" + item._id);
                      }}
                    >
                      <div className="card py-15 px-20">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <img
                            src="media/VMS.png"
                            alt="vms"
                            className="w-auto h-auto"
                          />
                          <label className="fw-bold mt-4">
                            Visitor Management
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  {item.meta[0].alise === "parking" && (
                    <div
                      className="me-5 c-p"
                      onClick={() => {
                        history("/modules/parking-management");
                      }}
                    >
                      <img
                        src="media/Group 26086277.png"
                        className="card-img-top"
                        alt="..."
                      />
                    </div>
                  )}
                  {item.meta[0].alise === "PMS" && (
                    <div
                      className="me-5 c-p"
                      onClick={() => {
                        history("/modules/pantry-management?id=" + item._id);
                      }}
                    >
                      <div className="card py-15 px-20">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <img
                            src="media/PMS.png"
                            alt="pms"
                            className="w-auto h-auto"
                          />
                          <label className="fw-bold mt-4">
                            Pantry Management
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  {item.meta[0].alise === "IAQ" && (
                    <div
                      className="me-5 c-p"
                      onClick={() => {
                        history("/modules/device-management?id=" + item._id);
                      }}
                    >
                      <div className="card py-15 px-20">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <img
                            src="media/IAQ.png"
                            alt="iaq"
                            className="w-auto h-auto"
                          />
                          <label className="fw-bold mt-4">
                            Indoor Air Quality
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
            {currentUser?.module?.length === 0 && (
              <div className="col-12 c-p text-center">
                <h5>No Modules found</h5>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
