/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  getCategoriesList,
  getCategoriesListByCustomer,
  getZones,
  deleteCategory,
} from "./requests";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../auth";
import Swal from "sweetalert2";
import ResponsivePagination from "react-responsive-pagination";
import { useDebouncedCallback } from "../../../utils";
import queryString from "query-string";

const API_URL = process.env.REACT_APP_API_URL;

export function PublicURL() {
  const history = useNavigate();
  const { currentUser } = useAuth();
  const [categoryList, setCategoryList] = useState([]);
  const [filterCategoryList, setFilterCategoryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const startIndex = (currentPage ? currentPage - 1 : 0) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dataSet = categoryList.slice(startIndex, endIndex);
  const [searchText, setSearchText] = useState("");
  const onSearch = useDebouncedCallback(() => {
    let newCategoryList = [...filterCategoryList];
    if (newCategoryList.length > 0) {
      if (searchText) {
        const iText = searchText.toLowerCase();
        newCategoryList = newCategoryList.filter(
          (x: any) =>
            x.name.toLowerCase().includes(iText) ||
            x.description.toLowerCase().includes(iText)
        );
      }
      setCategoryList(newCategoryList);
      setTotalPages(Math.ceil(newCategoryList.length / itemsPerPage));
    }
  }, 500);

  useEffect(() => {
    onSearch();
  }, [searchText]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const getCategory = async () => {
    try {
      const { data } =
        currentUser.role === "admin"
          ? await getZones()
          : await getCategoriesList();
      if (data) {
        setCategoryList(data);
        setTotalPages(Math.ceil(data.length / itemsPerPage));
        setFilterCategoryList(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const [zones, setZones] = useState<any[]>([]);

  useEffect(() => {
    const fetchZones = async () => {
      try {
        const { data } = await getZones();
        if (data) {
          setZones(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchZones();
  }, []);
  const generateEncodedString = (zone) => {
    if (zone && zone.building) { // Ensure zone and building exist
      const { _id, customer_id, building,floor_id } = zone;
      const { location_id } = building; // Access location_id from building
      const query_string = `pantry&tid=${currentUser.customer_id}&lid=${location_id}&fid=${floor_id}&zid=${_id}`;
      return btoa(query_string);
    }
    
    return ""; // return empty string if zone or building is undefined

  };
  
  
  const [encodedString, setEncodedString] = useState("");
  const [baseURL, setBaseURL] = useState("");
  useEffect(() => {
    if (zones.length > 0) {
      // console.log("Zones:", zones); // Log the zones array to check its contents
  
      zones.forEach((zone) => {
        if (zone && zone.building) { // Ensure zone and building are defined
          const { _id, customer_id, floor_id ,building} = zone;
          const { location_id } = building; // Access location_id from building
  
          const query_string = `pantry&tid=${currentUser.customer_id}&lid=${location_id}&fid=${floor_id}&zid=${_id}`;
          const encodedString = btoa(query_string);
          const base_url = `${window.location.host}/pms/home?q=`;
  
  
          setEncodedString(encodedString);
          setBaseURL(base_url);
          console.log(query_string);
        }
      });
    }
  }, [zones]);
  

  useEffect(() => {}, []);
  const [copied, setCopied] = useState(false);

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  // const actual_url=base_url+encodedString
  return (
    <div className="card card-flush my-5">
      <div className="card-header align-items-center py-5 gap-2 gap-md-5">
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <input
              type="text"
              value={searchText}
              data-kt-ecommerce-product-filter="search"
              className="form-control form-control-solid w-250px ps-12"
              placeholder="Search Category"
              onChange={(e) => {
                const value = e.target.value;
                setSearchText(value);
              }}
            />
          </div>
        </div>

        {/* <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
          <a
            onClick={(e) => {
              e.preventDefault();
              history("/modules/pantry-management/category-add");
            }}
            className="btn btn-primary"
          >
            
          </a>
        </div> */}
      </div>

      <div className="card-body pt-0">
        <table
          className="table align-middle table-row-dashed fs-6 gy-5"
          id="kt_ecommerce_products_table"
        >
          <thead>
            <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
              <th className="w-10px pe-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    data-kt-check="true"
                    data-kt-check-target="#kt_ecommerce_products_table .form-check-input"
                    value="1"
                  />
                </div>
              </th>
              <th className="min-w-250px">Name</th>
              <th className="min-w-150px">URL</th>
            </tr>
          </thead>
          <tbody className="fw-semibold text-gray-600">
            {dataSet.map((item: any, i) => {
              const zone = zones[i];
              const encodedString = generateEncodedString(zone);
              const baseURL = `${window.location.host}/pms/home?q=`;
              return (
                <tr key={i}>
                  <td>
                    <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={item.id}
                      />
                    </div>
                  </td>
                  <td>{item.name}</td>
                  <td className="pe-0">
                    <div
                      title={`${baseURL}${encodedString}`}
                    >{`${baseURL}${encodedString.substring(0, 50)}...`}</div>
                    {encodedString.length > 50 && (
                      <button
                        type="button"
                        className="btn btn-sm btn-light btn-icon btn-icon-primary ms-2"
                        onClick={() => handleCopy(`${baseURL}${encodedString}`)}
                      >
                        <i className="bi bi-clipboard"></i>
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
{/* 
<td className="pe-0">
                    <div
                      title={`${baseURL}${encodedString}`}
                    >{`${baseURL}${encodedString.substring(0, 50)}...`}</div>
                    {encodedString.length > 50 && (
                      <button
                        type="button"
                        className="btn btn-sm btn-light btn-icon btn-icon-primary ms-2"
                        onClick={() => handleCopy(`${baseURL}${encodedString}`)}
                      >
                        <i className="bi bi-clipboard"></i>
                      </button>
                    )}
                  </td> */}
          </tbody>
        </table>
        <div className="row mt-2">
          <div className="col-1">
            <select
              name="records_per_age"
              className="form-select form-select-lg form-select-solid w-auto"
              onChange={(e) => {
                const value = parseInt(e.target.value);
                setItemsPerPage(value);
                setTotalPages(Math.ceil(categoryList.length / value));
              }}
              value={itemsPerPage}
            >
              {new Array(5).fill(null).map((i, index) => {
                const value = 10 * (index + 1);
                return (
                  <option key={index} value={value}>
                    {value}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-11">
            {
              <ResponsivePagination
                total={totalPages}
                current={currentPage}
                onPageChange={(page) => handlePageChange(page)}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
