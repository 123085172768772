import ImageMapper from "react-img-mapper";
import { forwardRef, useImperativeHandle, useState } from "react";
import {
  getZonesByFloorId,
  getDeviceByZone,
} from "../../pod/components/requests";

const API_URL = process.env.REACT_APP_API_URL;
const DeviceMapView = forwardRef((props, ref) => {
  const [userLayout, setUserLayout] = useState({
    name: "userLayout",
    areas: [],
  });
  const [floorInfo, setFloorInfo]: any = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [hoveredArea, setHoveredArea]: any = useState(null);
  const [msg, setMsg]: any = useState(null);
  const [moveMsg, setMoveMsg]: any = useState(null);

  const updateFloorInfo = (floor) => {
    if (floor) {
      floor["path"] = `${API_URL}/${floor.floor_map}`;
      setFloorInfo(floor);
      getZoneList(floor).then((r: any) => {
        let items = r.filter((x) => x != null);
        let deviceList = [].concat.apply([], items);
        let zoneMapList: any = [];
        deviceList.forEach((item: any) => {
          const iZone = item.zone;
          const iZoneExist = zoneMapList.find((x) => x.zoneId === iZone._id);
          if (iZone && iZone.coordinates) {
            iZone.coordinates.forEach((item: any) => {
              if (item.path_type && !iZoneExist) {
                zoneMapList.push({
                  coords: item.coords ? item.coords : [],
                  fillColor: "rgba(0, 0, 255, 0.2)",
                  name: "P1",
                  preFillColor: "rgba(0, 0, 255, 0.15)",
                  shape: item.path_type,
                  zoneId: iZone._id,
                });
              }
            });
          }
          if (item.coordinates) {
            const cord = item.coordinates[0];
            zoneMapList.push({
              name: item.name,
              shape: "circle",
              coords: [cord.x, cord.y, 2],
              preFillColor: "red",
              lineWidth: 20,
            });
          }
        });
        setUserLayout({
          name: "userLayout",
          areas: zoneMapList,
        });
        setShowMap(true);
      });
    } else {
      setFloorInfo(null);
      setShowMap(true);
    }
  };
  const getZoneList = (floor) => {
    return new Promise((resolve, reject) => {
      getZonesByFloorId(floor._id)
        .then((r) => {
          let zoneList = r.data;
          let promiseList: any = [];
          zoneList.forEach((zone) => {
            promiseList.push(getDeviceListByZoneId(zone));
          });
          Promise.all(promiseList).then((values) => {
            resolve(values);
          });
        })
        .catch((r) => {
          resolve([]);
        });
    });
  };
  const getDeviceListByZoneId = (zone) => {
    return new Promise((resolve, reject) => {
      getDeviceByZone(zone._id)
        .then((r) => {
          resolve(r.data);
        })
        .catch((e) => {
          resolve(null);
        });
    });
  };
  const clicked = (area) => {
    setMsg(
      `You clicked on ${area.shape} at coords ${JSON.stringify(area.coords)} !`
    );
  };
  const clickedOutside = (evt) => {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    setMsg(`You clicked on the image at coords ${JSON.stringify(coords)} !`);
  };
  const moveOnImage = (evt) => {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    setMoveMsg(`You moved on the image at coords ${JSON.stringify(coords)} !`);
  };
  const enterArea = (area) => {
    setHoveredArea(area);
    setMsg(
      `You entered ${area.shape} ${area.name} at coords ${JSON.stringify(
        area.coords
      )} !`
    );
  };
  const leaveArea = (area) => {
    setHoveredArea(null);
    setMsg(
      `You leaved ${area.shape} ${area.name} at coords ${JSON.stringify(
        area.coords
      )} !`
    );
  };
  const moveOnArea = (area, evt) => {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    setMoveMsg(
      `You moved on ${area.shape} ${area.name} at coords ${JSON.stringify(
        coords
      )} !`
    );
  };
  const getTipPosition = (area) => {
    return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
  };
  useImperativeHandle(ref, () => ({
    updateFloorInfo: updateFloorInfo,
  }));
  return (
    <div className="row mb-10">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            {floorInfo && showMap && (
              <div className="row">
                <div className="col-12">
                  <div className="image-mapper">
                    <ImageMapper
                      src={floorInfo.path}
                      map={userLayout}
                      width={500}
                      onLoad={() => {}}
                      onClick={(area) => clicked(area)}
                      onMouseEnter={(area) => enterArea(area)}
                      onMouseLeave={(area) => leaveArea(area)}
                      onMouseMove={(area, _, evt) => moveOnArea(area, evt)}
                      onImageClick={(evt) => clickedOutside(evt)}
                      onImageMouseMove={(evt) => moveOnImage(evt)}
                      lineWidth={2}
                      strokeColor={"rgba(255, 255, 255, 0.1)"}
                    />
                    {hoveredArea && (
                      <span
                        className="tooltip"
                        style={{ ...getTipPosition(hoveredArea) }}
                      >
                        {hoveredArea && hoveredArea.name}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
export { DeviceMapView };
