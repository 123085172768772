import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../sdb/layout/core'

const ReportsPage: FC = () => (
    <>
        {/* begin::Row */}
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-12 text-center'>
                Reports
            </div>
        </div>
    </>
)

const ReportsWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{'Reports'}</PageTitle>
            <ReportsPage />
        </>
    )
}

export { ReportsWrapper }
