/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
// eslint-disable-next-line jsx-a11y/anchor-is-valid
// eslint-disable-next-line jsx-a11y/img-redundant-alt
import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import clsx from "clsx";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import Swal from "sweetalert2";
import { useAuth } from "../../../../app/modules/auth";
import { useListView } from "../../../../app/modules/apps/user-management/users-list/core/ListViewProvider";
import { NoRecords } from "../../../../app/modules/pod/components/no-records";
import { deleteAction, deleteLocations, getAction } from "./request";
import ResponsivePagination from "react-responsive-pagination";
import { useDebouncedCallback } from "../../../../app/utils";
const API_URL = process.env.REACT_APP_API_URL;

const SmartTable: FC<any> = forwardRef((props, ref) => {
  const { currentUser, logout } = useAuth();
  const [selectedItems, setSelectedItems]: any = useState([]);
  const [allRecords, setAllRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const startIndex = (currentPage ? currentPage - 1 : 0) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dataSet = allRecords.slice(startIndex, endIndex);
  const [filterCategoryList, setFilterCategoryList] = useState([]);
  const [searchText, setSearchText]: any = useState("");
  const onSearch = useDebouncedCallback(() => {
    let newCategoryList = [...filterCategoryList];
    if (newCategoryList.length > 0) {
      if (searchText) {
        const iText = searchText.toLowerCase();
        if (props.addBtnText === "Add User") {
          newCategoryList = newCategoryList.filter(
            (x: any) =>
              x.email.toLowerCase().includes(iText) ||
              x.first_name.toLowerCase().includes(iText)
          );
        } else {
          newCategoryList = newCategoryList.filter((x: any) =>
            x.name.toLowerCase().includes(iText)
          );
        }
      }
      setAllRecords(newCategoryList);
      setTotalPages(Math.ceil(newCategoryList.length / itemsPerPage));
    }
  }, 500);

  useEffect(() => {
    onSearch();
  }, [searchText]);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };
  const getRecords = async () => {
    try {
      const requestUrl = props.passUserId
        ? `${props.getURL}${currentUser.customer_id}/`
        : props.getURL;
      const { data } = await getAction(requestUrl);
      if (data) {
        setAllRecords(data);
        setFilterCategoryList(data);
        setTotalPages(Math.ceil(data.length / itemsPerPage));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteMultipleRecord = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // selectedItems.forEach((item: any) => {
        const deleteItemUrl = `${props.bulkdelete}`;
        deleteLocations(deleteItemUrl, selectedItems)
          .then((r) => {
            let newRecords = [...allRecords];
            const nr = newRecords.filter(
              (ditem: any) => selectedItems.includes(ditem._id) === false
            );
            setAllRecords(nr);
            setFilterCategoryList(nr);
          })
          .catch((error: any) => {
            Swal.fire("Deleted!", error?.response?.data?.detail, "error");
          });
        // });
        setSelectedItems([]);
      }
    });
  };
  const deleteRecord = async (item: any, rowIndex: number) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteItemUrl = `${props.deleteUrl}${item._id}/`;
        deleteAction(deleteItemUrl)
          .then((r) => {
            let newRecords = [...allRecords];
            const nr = newRecords.filter((ditem: any) => ditem._id != item);
            setAllRecords(nr);
            setFilterCategoryList(nr);
            getRecords();
          })
          .catch((error: any) => {
            Swal.fire("Deleted!", error?.response?.data?.detail, "error");
          });
      }
    });
  };
  const addBtnCallBack = () => {
    props.actionCallBack({
      action: "add",
    });
  };
  useImperativeHandle(ref, () => ({
    getRecords: getRecords,
  }));
  const renderCurrentSelection = (col, data, rowIndex) => {
    switch (col.display) {
      case "image":
        return (
          <td className={col.className}>
            <img
              src={`${API_URL}/${data[col.dataKey]}`}
              alt="image"
              className="table-image"
            />
          </td>
        );
      case "array":
        return (
          <td className={col.className} title={data[col.dataKey][0]}>
            {data[col.dataKey][0]}
          </td>
        );
      case "arrayObject":
        return (
          <td className={col.className}>
            {data[col.dataKey][0][col.objectKey]}
          </td>
        );
      case "object":
        return (
          <td
            className={col.className}
            title={data[col.dataKey][col.objectKey]}
          >
            {data[col.dataKey][col.objectKey]}
          </td>
        );
      case "prfoile":
        return (
          <td>
            <div className="d-flex align-items-center">
              {/* begin:: Avatar */}
              <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                <a href="#">
                  {data.profile_pic && data.profile_pic !== "<string>" ? (
                    <div className="symbol-label">
                      <img
                        src={toAbsoluteUrl(`/media/${data.avatar}`)}
                        alt={data.first_name}
                        className="w-100"
                      />
                    </div>
                  ) : (
                    <div
                      className={clsx(
                        "symbol-label fs-3",
                        `bg-light-${data.is_active ? "success" : "danger"}`,
                        `text-${data.is_active ? "success" : "danger"}`
                      )}
                    >
                      {data.first_name.charAt(0)}
                    </div>
                  )}
                </a>
              </div>
              <div className="d-flex flex-column">
                <a
                  href="#"
                  onClick={(e) => e.preventDefault()}
                  className="text-gray-800 text-hover-primary mb-1"
                >
                  {data.first_name}
                </a>
                <span>{data.mobile}</span>
              </div>
            </div>
          </td>
        );
      case "status":
        return (
          <td>
            <div
              className={
                data[col.dataKey]
                  ? "badge badge-light-success fw-bolder"
                  : "badge badge-light-danger fw-bolder"
              }
            >
              {data[col.dataKey] ? "Active" : "Inactive"}
            </div>
          </td>
        );
      case "date":
        return <td>{new Date(data.created_at).toLocaleDateString()}</td>;
      case "action":
        return (
          col.actions.length > 0 && (
            <td>
              <a
                className="btn btn-light btn-active-light-primary btn-sm"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
                data-bs-toggle="dropdown"
                aria-expanded="true"
                id="dropdownMenuButton1"
              >
                Actions
                <KTIcon iconName="down" className="fs-5 m-0" />
              </a>
              <div
                className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
                data-kt-menu="flase"
                aria-labelledby="dropdownMenuButton1"
              >
                {col.actions.map((actionItem: any, key) => {
                  if (actionItem.label === "View") {
                    return (
                      <div className="menu-item px-3" key={key}>
                        <a
                          className="menu-link px-3"
                          onClick={() => {
                            props.actionCallBack({
                              action: "view",
                              rowData: { item: data, rowIndex: rowIndex },
                            });
                          }}
                        >
                          View
                        </a>
                      </div>
                    );
                  } else if (actionItem.label === "Edit") {
                    return (
                      <div className="menu-item px-3" key={key}>
                        <a
                          className="menu-link px-3"
                          onClick={() => {
                            props.actionCallBack({
                              action: "edit",
                              rowData: { item: data, rowIndex: rowIndex },
                            });
                          }}
                        >
                          Edit
                        </a>
                      </div>
                    );
                  } else if (actionItem.label === "Delete") {
                    return (
                      <div className="menu-item px-3" key={key}>
                        <a
                          className="menu-link px-3"
                          data-kt-users-table-filter="delete_row"
                          onClick={() => deleteRecord(data, rowIndex)}
                        >
                          Delete
                        </a>
                      </div>
                    );
                  } else if (actionItem.label === "Access") {
                    return (
                      <div className="menu-item px-3" key={key}>
                        <a
                          className="menu-link px-3"
                          onClick={() => {
                            props.actionCallBack({
                              action: "access",
                              rowData: { item: data, rowIndex: rowIndex },
                            });
                          }}
                        >
                          Access
                        </a>
                      </div>
                    );
                  } else if (actionItem.label === "Licenses") {
                    return (
                      <div className="menu-item px-3" key={key}>
                        <a
                          className="menu-link px-3"
                          onClick={() => {
                            props.actionCallBack({
                              action: "Licenses",
                              rowData: { item: data, rowIndex: rowIndex },
                            });
                          }}
                        >
                          Licenses
                        </a>
                      </div>
                    );
                  }
                })}
              </div>
            </td>
          )
        );
      default:
        return <td>{data[col.dataKey]}</td>;
    }
  };
  useEffect(() => {
    getRecords();
  }, []);

  return (
    <>
      {allRecords.length == 0 && searchText === null ? (
        <NoRecords
          helpText={props.noRecordHelpText}
          addBtnCallBack={addBtnCallBack}
          imagePage={props.imagePathForNoRecord}
          addButtonText={props.addBtnText}
        />
      ) : (
        <div className="col-12">
          <div className="card">
            <div className="card-header border-0 pt-6">
              <div className="card-title">
                <div className="d-flex align-items-center position-relative my-1">
                  <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <input
                    type="text"
                    data-kt-user-table-filter="search"
                    className="form-control form-control-solid w-250px ps-14"
                    placeholder={props.serachPlaceholder}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </div>
              <div className="card-toolbar">
                <div
                  className="d-flex justify-content-end"
                  data-kt-user-table-toolbar="base"
                >
                  {props.hideActions ? (
                    ""
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          props.actionCallBack({
                            action: "add",
                          });
                        }}
                      >
                        <KTIcon iconName="plus" className="fs-2" />
                        {props.addBtnText}
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger ms-2"
                        disabled={selectedItems.length == 0}
                        onClick={() => deleteMultipleRecord()}
                      >
                        <KTIcon iconName="trash" className="fs-2" />
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="card-body py-4">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                  <thead>
                    <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                      {props.multiSelectRequired && (
                        <th className="min-w-50px">
                          <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              data-kt-check={
                                selectedItems.length === allRecords.length
                              }
                              data-kt-check-target="#kt_table_users .form-check-input"
                              checked={
                                selectedItems.length === allRecords.length
                              }
                              onChange={(e) => {
                                let newSelectedItem: any = [];
                                if (e.target.checked) {
                                  newSelectedItem = allRecords.map(
                                    (x: any) => x._id
                                  );
                                } else {
                                  newSelectedItem = [];
                                }
                                setSelectedItems(newSelectedItem);
                              }}
                            />
                          </div>
                        </th>
                      )}
                      {props.cols.map((col, i) => {
                        return (
                          <th key={i} className={col.className}>
                            {col.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="text-gray-600 fw-bold">
                    {dataSet.map((item: any, i) => {
                      return (
                        <tr role="row" key={item._id}>
                          {props.multiSelectRequired && (
                            <td>
                              <div className="form-check form-check-custom form-check-solid">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  data-kt-check={selectedItems.includes(
                                    item._id
                                  )}
                                  data-kt-check-target="#kt_table_users .form-check-input"
                                  checked={selectedItems.includes(item._id)}
                                  onChange={(e) => {
                                    let newSelectedItem: any = [
                                      ...selectedItems,
                                    ];
                                    if (e.target.checked) {
                                      newSelectedItem.push(item._id);
                                    } else {
                                      newSelectedItem = newSelectedItem.filter(
                                        (ik: any) => ik != item._id
                                      );
                                    }
                                    setSelectedItems(newSelectedItem);
                                  }}
                                />
                              </div>
                            </td>
                          )}
                          {props.cols.map((col, colIndex) => {
                            return (
                              <React.Fragment key={`${i}_${colIndex}`}>
                                {renderCurrentSelection(col, item, i)}
                              </React.Fragment>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="row mt-2">
                <div className="col-1">
                  <select
                    name="records_per_age"
                    className="form-select form-select-lg form-select-solid w-auto"
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      setItemsPerPage(value);
                      setTotalPages(Math.ceil(allRecords.length / value));
                    }}
                    value={itemsPerPage}
                  >
                    {new Array(10).fill(null).map((i, index) => {
                      const value = 5 * (index + 1);
                      return (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-11">
                  {
                    <ResponsivePagination
                      total={totalPages}
                      current={currentPage}
                      onPageChange={(page) => handlePageChange(page)}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export { SmartTable };
