import { useState } from "react";
import clsx from "clsx";
import { KTIcon } from "../../../helpers";
import { HeaderUserMenu } from "../../../partials";
import { useLayout } from "../../core";
import { useAuth } from "../../../../app/modules/auth";

const itemClass = "ms-1 ms-md-4";
const userAvatarClass = "symbol-35px";
const btnIconClass = "fs-2";
const API_URL = process.env.REACT_APP_API_URL;

const Navbar = () => {
  const { currentUser, logout } = useAuth();
  const [imageUrl, setImgUrl] = useState(
    currentUser?.profile_pic ? `${API_URL}/${currentUser?.profile_pic}` : ""
  );

  const { config } = useLayout();

  const onError = () => {
    setImgUrl("");
  };
  return (
    <div className="app-navbar flex-shrink-0">
      <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          {imageUrl ? (
            <img src={imageUrl} onError={onError} />
          ) : (
            <div
              className={clsx(
                "symbol-label fs-3",
                `bg-light-${currentUser?.is_active ? "success" : "danger"}`,
                `text-${currentUser?.is_active ? "success" : "danger"}`
              )}
            >
              {currentUser?.first_name.charAt(0)}
            </div>
          )}
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div
          className="app-navbar-item d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="kt_app_header_menu_toggle"
          >
            <KTIcon iconName="text-align-left" className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  );
};

export { Navbar };
