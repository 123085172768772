/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { KTIcon } from "../../../sdb/helpers";
import Select from "react-select";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import clsx from "clsx";
import Modal from "react-bootstrap/Modal";
import {
  addCustomer,
  updateCustomer,
  uuidv4,
  updateCustomerAdmin,
  deleteCustomerAdmin,
} from "./requests";
import { PageTitle } from "../../../sdb/layout/core";

const editUserSchema = Yup.object().shape({
  address: Yup.string().required("Address is required"),
  name: Yup.string().required("Name is required"),
  is_active: Yup.object({
    value: Yup.string().required("Status is required"),
  }),
  contact: Yup.string().required("Notes is required"),
  notes: Yup.string().required("Notes is required"),
});
export function ViewCustomers(props) {
  const history: any = useNavigate();
  const location: any = useLocation();
  const [customerData, setCustomerData]: any = useState({});
  const [adminItems, setAdminList]: any = useState([]);
  const [selectedItem, setSelectedItem]: any = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleModalSave = () => {
    if (customerData._id) {
      updateCustomerAdmin(selectedItem.email)
        .then(() => {
          let adminList = [...adminItems];
          let itemIndex = adminItems.findIndex((x) => x.id === selectedItem.id);
          if (itemIndex !== -1) {
            adminList[itemIndex] = { ...selectedItem };
          } else {
            adminList.push(selectedItem);
          }
          setShowModal(false);
          setAdminList(adminList);
          setSelectedItem({
            email: "",
          });
        })
        .catch((e: any) => {
          Swal.fire("User Info!", e?.response?.data?.detail, "error");
        });
    } /* else {
      let parsms = {
        first_name: selectedItem.name,
        last_name: selectedItem.name,
        email: selectedItem.email,
        is_active: true,
        role: "admin",
        customer_id: customerData._id,
      };
      addCustomerAdmin(parsms)
        .then(() => {
          let adminList = [...adminItems];
          let itemIndex = adminItems.findIndex((x) => x.id === selectedItem.id);
          if (itemIndex !== -1) {
            adminList[itemIndex] = { ...selectedItem };
          } else {
            adminList.push(selectedItem);
          }
          setShowModal(false);
          setAdminList(adminList);
          setSelectedItem({
            id: null,
            name: "",
            email: "",
          });
        })
        .catch((e: any) => {
          Swal.fire("User Info!", e?.response?.data?.detail, "error");
        });
    } */ else {
      let adminList = [...adminItems];
      let itemIndex = adminItems.findIndex((x) => x.id === selectedItem.id);
      if (itemIndex !== -1) {
        adminList[itemIndex] = { ...selectedItem };
      } else {
        adminList.push(selectedItem);
      }
      setShowModal(false);
      setAdminList(adminList);
      setSelectedItem({
        id: null,
        name: "",
        email: "",
      });
    }
  };

  const deleteAdmin = (args) => {
    if (customerData._id) {
      deleteCustomerAdmin(args.email)
        .then((r) => {
          let adminList = [...adminItems];
          adminList = adminList.filter((x) => x.id !== args.id);
          setAdminList(adminList);
        })
        .catch((e: any) => {
          Swal.fire("Deleted!", e?.response?.data?.detail, "error");
        });
    } else {
      let adminList = [...adminItems];
      adminList = adminList.filter((x) => x.id !== args.id);
      setAdminList(adminList);
    }
  };
  /* const deleteAdmin = (args) => {
    if (customerData._id) {
      deleteAdminUser(args.email)
        .then((r) => {
          let adminList = [...adminItems];
          adminList = adminList.filter((x) => x.id !== args.id);
          setAdminList(adminList);
        })
        .catch((e: any) => {
          Swal.fire("Deleted!", e?.response?.data?.detail, "error");
        });
    } else {
      let adminList = [...adminItems];
      adminList = adminList.filter((x) => x.id !== args.id);
      setAdminList(adminList);
    }
  }; */
  const [customerInfo] = useState<any>({
    name: customerData.name || "",
    address: (customerData.address && customerData.address[0]) || "",
    is_active:
      customerData.is_active === false
        ? { value: "0", label: "Inactive" }
        : { value: "1", label: "Active" },
    notes: customerData.notes || "",
  });
  const formik: any = useFormik({
    initialValues: customerInfo,
    validationSchema: editUserSchema,
    onSubmit: async (values: any, { setSubmitting }) => {
      setSubmitting(true);
      try {
        values["address"] = [values["address"]];
        values["is_active"] = values.is_active.value == "0" ? false : true;
        values["admin"] = adminItems;
        var params = {
          name: values.name,
          address: [values["address"]],
          contact: values.contact,
          notes: values.name,
          is_active: values.is_active.value == "0" ? false : true,
        };
        const data: Promise<any> = (await customerData._id)
          ? updateCustomer(params, customerData._id)
          : addCustomer(values);
        data
          .then((res: any) => {
            history("/customers");
          })
          .catch((error: any) => {
            Swal.fire("Customer!", error.response.data.detail, "error");
          });
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(true);
      }
    },
  });
  useEffect(() => {
    const selectedCustoemr = location.state;
    if (selectedCustoemr) {
      setCustomerData(selectedCustoemr);
      let adminList = selectedCustoemr.admin;
      adminList.forEach((item) => {
        item["id"] = uuidv4();
      });
      setAdminList(adminList);
      let patch_object = {
        name: selectedCustoemr.name || "",
        address:
          (selectedCustoemr.address && selectedCustoemr.address[0]) || "",
        is_active:
          selectedCustoemr.is_active === false
            ? { value: "0", label: "Inactive" }
            : { value: "1", label: "Active" },
        notes: selectedCustoemr.notes || "",
        contact: selectedCustoemr.contact || "",
      };
      formik.setValues(patch_object);
    }
  }, []);
  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">
              Add Admin
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {customerData._id ? (
              ""
            ) : (
              <div className="mb-3">
                <label className="col-form-label required">Name:</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={(e) => {
                    let item = { ...selectedItem };
                    item.name = e.target.value;
                    setSelectedItem(item);
                  }}
                  value={selectedItem.name}
                />
              </div>
            )}

            <div className="mb-3">
              <label className="col-form-label required">Email:</label>
              <input
                type="email"
                className="form-control"
                name="email"
                onChange={(e) => {
                  let item = { ...selectedItem };
                  item.email = e.target.value;
                  setSelectedItem(item);
                }}
                value={selectedItem.email}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => handleModalSave()}
            disabled={
              customerData._id
                ? !selectedItem.email
                : !selectedItem.name || !selectedItem.email
            }
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex flex-wrap flex-stack mb-6">
        <PageTitle
          breadcrumbs={[
            {
              title: "Customers",
              path: "/customers",
              isSeparator: false,
              isActive: false,
            },
            {
              title: "Manage Customers",
              path: "",
              isSeparator: true,
              isActive: false,
            },
          ]}
        >
          {customerData._id ? "Edit Customer" : "Add Customer"}
        </PageTitle>
      </div>
      <div className="row">
        <form className="form" onSubmit={formik.handleSubmit} noValidate>
          <div className="col-12 mb-4">
            <div className="card">
              {/* begin::Header */}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    Customer Details
                  </span>
                </h3>
                <div
                  className="card-toolbar"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-trigger="hover"
                  title="Click to add a user"
                >
                  <button
                    disabled={
                      formik.isSubmitting || !formik.isValid || !formik.touched
                    }
                    className="btn btn-sm btn-primary"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className="card-body py-3">
                <div className="row">
                  <div className="col-4 mb-3">
                    <label className="required fw-bold fs-6 mb-2">Name</label>
                    <input
                      placeholder="Name"
                      {...formik.getFieldProps("name")}
                      type="text"
                      name="name"
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0",
                        {
                          "is-invalid":
                            formik.touched?.name && formik.errors?.name,
                        },
                        {
                          "is-valid":
                            formik.touched?.name && !formik.errors?.name,
                        }
                      )}
                      autoComplete="off"
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{String(formik.errors.name)}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-4 mb-3">
                    <label className="required fw-bold fs-6 mb-2">
                      Contact
                    </label>
                    <input
                      placeholder="Contact"
                      {...formik.getFieldProps("contact")}
                      type="text"
                      name="contact"
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0",
                        {
                          "is-invalid":
                            formik.touched?.contact && formik.errors?.contact,
                        },
                        {
                          "is-valid":
                            formik.touched?.contact && !formik.errors?.contact,
                        }
                      )}
                      autoComplete="off"
                    />
                    {formik.touched.contact && formik.errors.contact && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {String(formik.errors.contact)}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-4 mb-3">
                    <label className="required fw-bold fs-6 mb-2">Status</label>
                    <Select
                      {...formik.getFieldProps("is_active")}
                      name="is_active"
                      options={[
                        { value: "1", label: "Active" },
                        { value: "0", label: "Inactive" },
                      ]}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        formik.setFieldValue("is_active", e);
                      }}
                    />
                    {formik.touched.is_active && formik.errors.is_active && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {String(formik.errors.is_active)}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-6 mb-3">
                    <label className="required fw-bold fs-6 mb-2">
                      Primary Address
                    </label>
                    <textarea
                      {...formik.getFieldProps("address")}
                      name="address"
                      className={clsx(
                        "form-control form-control-lg form-control-solid",
                        {
                          "is-invalid":
                            formik.touched?.is_active &&
                            formik.errors?.is_active,
                        },
                        {
                          "is-valid":
                            formik.touched?.is_active &&
                            !formik.errors?.is_active,
                        }
                      )}
                      rows={3}
                    ></textarea>
                    {formik.touched.address && formik.errors.address && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {String(formik.errors.address)}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-6">
                    <label className="required fw-bold fs-6 mb-2">Note</label>
                    <textarea
                      {...formik.getFieldProps("notes")}
                      name="notes"
                      className={clsx(
                        "form-control form-control-lg form-control-solid",
                        {
                          "is-invalid":
                            formik.touched?.is_active &&
                            formik.errors?.is_active,
                        },
                        {
                          "is-valid":
                            formik.touched?.is_active &&
                            !formik.errors?.is_active,
                        }
                      )}
                      rows={3}
                    ></textarea>
                    {formik.touched.notes && formik.errors.notes && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {String(formik.errors.notes)}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* begin::Body */}
            </div>
          </div>
          <div className="col-12">
            <div className="card">
              {/* begin::Header */}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    Admin List
                  </span>
                </h3>
                <div
                  className="card-toolbar"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-trigger="hover"
                  title="Click to add a user"
                >
                  <a
                    onClick={(e) => {
                      setSelectedItem({
                        name: "",
                        email: "",
                        id: uuidv4(),
                      });
                      setShowModal(true);
                    }}
                    className="btn btn-sm btn-light-primary"
                  >
                    <KTIcon iconName="plus" className="fs-3" />
                    Add Admin
                  </a>
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className="card-body py-3">
                {/* begin::Table container */}
                <div className="table-responsive">
                  {/* begin::Table */}
                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    {/* begin::Table head */}
                    <thead>
                      <tr className="fw-bold text-muted">
                        <th className="min-w-150px">Name</th>
                        <th className="min-w-140px">Email</th>
                        <th className="min-w-100px text-end">Actions</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {adminItems.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>
                              <div className="d-flex justify-content-end flex-shrink-0">
                                {!customerData._id && (
                                  <a
                                    onClick={(e) => {
                                      setSelectedItem(item);
                                      setShowModal(true);
                                    }}
                                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                  >
                                    <KTIcon
                                      iconName="pencil"
                                      className="fs-3"
                                    />
                                  </a>
                                )}

                                <a
                                  onClick={(e) => {
                                    deleteAdmin(item);
                                  }}
                                  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                >
                                  <KTIcon iconName="trash" className="fs-3" />
                                </a>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
              {/* begin::Body */}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
