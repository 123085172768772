/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../sdb/layout/core";
import { useNavigate } from "react-router-dom";

const DashboardPage: FC<{ history: any }> = ({ history }) => (
  <>
    {/* begin::Row */}
    <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
      <div className="col-12 text-center">
        <h2>Welcome to Smart digibuild</h2>
        <div className="mt-8">
          {" "}
          <img src="media/dashboard.png" alt="dashboard.png" />
        </div>
      </div>
    </div>
  </>
);

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  const history = useNavigate();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage history={history} />
    </>
  );
};

export { DashboardWrapper };
