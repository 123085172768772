import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export function addController(data: any) {
  return axios.post<any>(`${API_URL}/controller/create/`, data);
}
export function updateController(data: any, id: string) {
  return axios.put<any>(`${API_URL}/controller/update/${id}/`, data);
}
export function getControllerSetup() {
  return axios.get<any>(`${API_URL}/controller/`);
}
export function deleteController(id: number) {
  return axios.delete<any>(`${API_URL}/controller/delete/${id}/`);
}

// alert methods
export function addAlert(data: any) {
  return axios.post<any>(`${API_URL}/iaq/v1/alerts/create/`, data);
}
export function updateAlert(data: any, id: string) {
  return axios.put<any>(`${API_URL}/iaq/v1/alerts/update/${id}/`, data);
}
export function getAlertByCustomerId(cId: any) {
  return axios.get<any>(`${API_URL}/iaq/v1/alerts/customer/${cId}`);
}
export function getIaqDataByFloorId(fId: any) {
  return axios.get<any>(`${API_URL}/data/floor/${fId}/`);
}
export function getIaqDataByZoneId(zId: any) {
  return axios.get<any>(`${API_URL}/data/zone/${zId}/`);
}
