/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { useAuth } from "../../auth";
import { addController, updateController } from "./request";
import Select from "react-select";

export function ControllerAdd(props) {
  const history = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const ControllerInfo: any = location.state;

  const [Controls] = useState<any>({
    name: ControllerInfo?.name || "",
    description: ControllerInfo?.description || "",
    is_active:
      ControllerInfo?.is_active === false
        ? { value: "0", label: "Inactive" }
        : { value: "1", label: "Active" },
    controller_type: ControllerInfo?.controller_type || "",
    connection_config: JSON.stringify(ControllerInfo?.connection_config) || "",
    field_config: JSON.stringify(ControllerInfo?.field_config) || "",
  });

  const addControllerSchema = Yup.object().shape({
    description: Yup.string().required("Description is required"),
    name: Yup.string().required("Name is required"),
    controller_type: Yup.string().required("Type is required"),
    connection_config: Yup.string().required(
      "Connection configuration is required"
    ),
    field_config: Yup.string().required("Field Configuration is required"),
    is_active: Yup.object({
      value: Yup.string().required("Status is required"),
    }),
  });

  const formik = useFormik({
    initialValues: Controls,
    validationSchema: addControllerSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const params = {
          name: values.name,
          description: values.description,
          controller_type: values.controller_type,
          is_active: values.is_active.value === "0" ? false : true,
          connection_config: JSON.parse(values.connection_config),
          field_config: JSON.parse(values.field_config),
          customer_id: currentUser.customer_id,
          module_id: currentUser.module_id[2],
        };
        /*  const param = {
          name: values.name,
          description: values.description,
          controller_type: values.controller_type,
          is_active: values.is_active.value === "0" ? false : true,
          connection_config: values.connection_config,
          field_config: values.field_config,
          customer_id: currentUser.customer_id,
          module_id: currentUser.module_id[2],
        }; */
        /*  values["is_active"] = values.is_active.value === "0" ? false : true;
        values["connection_config"] = JSON.parse(values.connection_config);
        values["field_config"] = JSON.parse(values.field_config);
        values["customer_id"] = currentUser.customer_id;
        values["module_id"] = currentUser.module_id[2]; */
        const data: Promise<any> = (await ControllerInfo?._id)
          ? updateController(params, ControllerInfo?._id)
          : addController(params);
        data
          .then((res: any) => {
            console.log(res, "res");
            history("/modules/device-management/");
          })
          .catch((error: any) => {
            console.log(error, "error");
          });
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(true);
      }
    },
  });

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-flex flex-stack"
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
              IAQ Management
            </h1>
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted cursor-pointer">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    history("/modules");
                  }}
                  className="text-muted text-hover-primary "
                >
                  Modules
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px"></span>
              </li>
              <li className="breadcrumb-item text-muted cursor-pointer">
                {" "}
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    history("/modules/device-management");
                  }}
                  className="text-muted text-hover-primary"
                >
                  IAQ Management
                </a>
              </li>

              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px"></span>
              </li>
              <li className="breadcrumb-item text-muted">
                {ControllerInfo ? "Edit" : "Add"} Controller
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-xxl"
        >
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="card p-5">
                  <div className="row mb-7">
                    <div className="col-6">
                      <label className="required fw-bold fs-6 mb-2">Name</label>
                      <input
                        type="text"
                        {...formik.getFieldProps("name")}
                        name="name"
                        className={clsx(
                          "form-control form-control-solid mb-3 mb-lg-0",
                          {
                            "is-invalid":
                              formik.touched.mobile && formik.errors.mobile,
                          },
                          {
                            "is-valid":
                              formik.touched.mobile && !formik.errors.mobile,
                          }
                        )}
                        placeholder="name"
                        aria-label="name"
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">
                              {String(formik.errors.name)}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-6">
                      <label className="required fw-bold fs-6 mb-2">
                        Description
                      </label>
                      <input
                        type="text"
                        {...formik.getFieldProps("description")}
                        name="description"
                        className="form-control form-control-solid mb-3 mb-lg-0"
                        placeholder="description"
                        aria-label="description"
                      />
                      {formik.touched.description && formik.errors.description && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">
                              {String(formik.errors.description)}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-7">
                    <div className="col-6">
                      <div className="">
                        <label className="required fw-bold fs-6 mb-2">
                          Type
                        </label>
                        <select
                          //   disabled={viewMode === "view"}
                          className="form-select form-select-solid form-select-lg  mb-3 mb-lg-0"
                          {...formik.getFieldProps("controller_type")}
                        >
                          <option value="">Select role</option>
                          <option value="MQTT">MQTT</option>
                          <option value="ABCD">ABCD</option>
                          {/*   <option value="">Select role</option>
                  {roleList.map((item, roleIndex) => {
                    return (
                      <option value={item} key={roleIndex}>
                        {item}
                      </option>
                    );
                  })} */}
                        </select>
                        {formik.touched.controller_type &&
                          formik.errors.controller_type && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">
                                  {String(formik.errors.controller_type)}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="mt-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Field Configuration
                        </label>
                        <textarea
                          {...formik.getFieldProps("field_config")}
                          name="field_config"
                          className={
                            "form-control form-control-lg form-control-solid"
                          }
                          rows={3}
                        ></textarea>
                        {formik.touched.field_config &&
                          formik.errors.field_config && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">
                                  {String(formik.errors.field_config)}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="col-6">
                      <label className="required fw-bold fs-6 mb-2">
                        Connection Configuration
                      </label>
                      <textarea
                        {...formik.getFieldProps("connection_config")}
                        name="connection_config"
                        className={
                          "form-control form-control-lg form-control-solid"
                        }
                        rows={6}
                      ></textarea>
                      {formik.touched.connection_config &&
                        formik.errors.connection_config && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">
                                {String(formik.errors.connection_config)}
                              </span>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="row mb-7 ">
                    <div className="col-6">
                      <label className="required fw-bold fs-6 mb-2">
                        Status
                      </label>
                      <Select
                        {...formik.getFieldProps("is_active")}
                        name="is_active"
                        options={[
                          { value: "1", label: "Active" },
                          { value: "0", label: "Inactive" },
                        ]}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          formik.setFieldValue("is_active", e);
                        }}
                      />
                      {formik.touched.is_active && formik.errors.is_active && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">
                              {String(formik.errors.is_active)}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="text-end col-6 pt-6">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        data-kt-users-modal-action="submit"
                        /*   disabled={
                    formik.isSubmitting || !formik.isValid || !formik.touched
                  } */
                      >
                        <span className="indicator-label">
                          {ControllerInfo ? "Save Changes" : "Add New"}
                        </span>
                        {formik.isSubmitting && (
                          <span className="indicator-progress">
                            Please wait...{" "}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
