export function NoRecords(props) {
    return (
        <div className='col-12 text-center'>
            <h3>No Records Found</h3>
            <p className='m-5 help-text mb-4'>{props.helpText}</p>
            <button type='button' className='btn btn-primary mb-4' onClick={() => { props.addBtnCallBack('add') }}>
                {props.addButtonText}
            </button>
            <div className='mt-4 mb-4'>
                <img src={props.imagePage} alt="..." />
            </div>
        </div>
    )
}