import { useEffect, useState } from "react";
import { getLocationByCustomer } from "../pod/components/requests";
import { toAbsoluteUrl } from "../../../sdb/helpers";
const API_URL = process.env.REACT_APP_API_URL;

export function VisitorInfo(props) {
  const [selectedLocation, setSelectedLocation] = useState([]);
  const getLocations = async (customerId: number) => {
    try {
      const { data } = await getLocationByCustomer(customerId);
      if (data) {
        let selectedItems = data.filter((x) =>
          props.visitorInfo.visitor_profile.location_id.includes(x._id)
        );
        setSelectedLocation(selectedItems);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getLocations(props?.visitorInfo?.visitor_profile?.customer_id);
  }, []);
  console.log(props, "props");
  return (
    <div className="">
      <div className="row mb-3">
        <div className="text-end">
          <img
            src={
              props.visitorInfo.visitor_profile.profile_pic
                ? `${API_URL}/${props.visitorInfo.visitor_profile.profile_pic}`
                : toAbsoluteUrl("/media/avatars/blank.png")
            }
            alt="profile pic"
            className="w-25 h-auto"
          />
        </div>
        <label className="col-sm-4 fw-bold col-form-label">STATUS</label>
        <div className="col-sm-8">
          <div
            className={`badge fw-bolder ${
              props?.visitorInfo.visit_data.status === "pending"
                ? " badge-light-warning"
                : ""
            } ${
              props?.visitorInfo.visit_data.status === "approved"
                ? "badge-light-success"
                : ""
            } ${
              props?.visitorInfo.visit_data.status === "rejected"
                ? "badge-light-danger"
                : ""
            }`}
          >
            {props?.visitorInfo.visit_data.status === "pending"
              ? "Pending"
              : props?.visitorInfo.visit_data.status === "approved"
              ? "Approved"
              : props?.visitorInfo.visit_data.status}
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-4 fw-bold col-form-label">VISITOR ID</label>
        <div className="col-sm-8">
          <label className="col-form-label">
            {props.visitorInfo.visit_data.visitor_id}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-4 fw-bold col-form-label">USERNAME</label>
        <div className="col-sm-8">
          <label className="col-form-label">
            {props.visitorInfo.visitor_profile.full_name}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-4 fw-bold col-form-label">MAIL ID</label>
        <div className="col-sm-8">
          <label className="col-form-label">
            {props.visitorInfo.visitor_profile.email}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-4 fw-bold col-form-label">MOBILE</label>
        <div className="col-sm-8">
          <label className="col-form-label">
            {props.visitorInfo.visitor_profile.phone_number}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-4 fw-bold col-form-label">HOST NAME</label>
        <div className="col-sm-8">
          <label className="col-form-label">
            {props.visitorInfo.visit_data.host_name
              ? props.visitorInfo.visit_data.host_name
              : props.visitorInfo.host_name}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-4 fw-bold col-form-label">ACCESS TOKEN</label>
        <div className="col-sm-8">
          <label className="col-form-label">
            {props.visitorInfo.visit_data.access_token}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-4 fw-bold col-form-label">VISITING DATE</label>
        <div className="col-sm-8">
          <label className="col-form-label">
            {props.visitorInfo.visit_data.visiting_date_time
              ? new Date(
                  props.visitorInfo.visit_data.visiting_date_time
                ).toLocaleDateString()
              : ""}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-4 fw-bold col-form-label text-uppercase">
          DURATION
        </label>
        <div className="col-sm-8">
          <label className="col-form-label">
            {props.visitorInfo.visit_data.duration}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-4 fw-bold col-form-label text-uppercase">
          PURPOSE OF VISIT
        </label>
        <div className="col-sm-8">
          <label className="col-form-label">
            {props.visitorInfo.visit_data.purpose_of_visit}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-4 fw-bold col-form-label">EMAIL</label>
        <div className="col-sm-8">
          <label className="col-form-label">
            {props.visitorInfo.visitor_profile.email}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-4 fw-bold col-form-label text-uppercase">
          SIGN IN TIME
        </label>
        <div className="col-sm-8">
          <label className="col-form-label">
            {props.visitorInfo.visit_data.sign_in_time == null
              ? ""
              : new Date(
                  props.visitorInfo.visit_data.sign_in_time
                ).toLocaleTimeString()}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-4 fw-bold col-form-label text-uppercase">
          SIGN OUT TIME
        </label>
        <div className="col-sm-8">
          <label className="col-form-label">
            {props.visitorInfo.visit_data.sign_out_time == null
              ? ""
              : new Date(
                  props.visitorInfo.visit_data.sign_out_time
                ).toLocaleTimeString()}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-4 fw-bold col-form-label">COMPANY NAME</label>
        <div className="col-sm-8">
          <label className="col-form-label">
            {props.visitorInfo.visitor_profile.company_name}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-4 fw-bold col-form-label">LOCATION</label>
        <div className="col-sm-8">
          <label className="col-form-label">
            {selectedLocation.map((item: any, i) => {
              return (
                <span key={i}>{`${item.name} ${
                  selectedLocation.length - 1 === i ? "" : ", "
                }`}</span>
              );
            })}
          </label>
        </div>
      </div>
    </div>
  );
}
