import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../sdb/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { ReportsWrapper } from "../pages/reports/Report";
import { getCSSVariableValue } from "../../sdb/assets/ts/_utils";
import { WithChildren } from "../../sdb/helpers";
import { ModuelsPage } from "../modules/apps/modules-page/modulesPage";
import { VisitorManagementPage } from "../modules/apps/visitor-management";
import { PantryManagementPage } from "../modules/apps/pantry-management";
import { ItemAdd } from "../modules/apps/pantry-management/ItemAdd";
import { CategoryAdd } from "../modules/apps/pantry-management/CategoryAdd";
import { Customers } from "../pages/customers/customer";
import { Permissions } from "../pages/permissions/permissions";
import { ViewCustomers } from "../pages/customers/view-customer";
import { ViewPermissions } from "../pages/permissions/view-permissions";
import ViewModules from "../modules/apps/modules-page/view-modules";
import Access from "../pages/customers/access";
import { AddUserGroup } from "../pages/customers/add-usergroup";
import { AddApi } from "../pages/customers/add-api";
import { ViewUsergroup } from "../pages/customers/view-usergroup";
import { ViewApi } from "../pages/customers/view-api";
import { DeviceManagementPage } from "../modules/apps/device-management";
import { ControllerAdd } from "../modules/apps/device-management/ControllerAdd";
import { Licenses } from "../modules/settings/licenses";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const UsersPage = lazy(
    () => import("../modules/apps/user-management/UsersPage")
  );
  const SettingsPage = lazy(() => import("../modules/settings/settings"));
  const PodPage = lazy(() => import("../modules/pod/pod-page"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        {/* <Route path="customers/list" element={<Customers />} /> */}
        <Route path="customers" element={<Customers />} />
        <Route path="permissions/list" element={<Permissions />} />
        <Route path="permissions" element={<Permissions />} />
        <Route path="customers/add" element={<ViewCustomers />} />
        <Route
          path="customers/:customerId/manage"
          element={<ViewCustomers />}
        />
        <Route path="customers/:customerId/access" element={<Access />} />
        {/* <Route path="customers/list/:customerId/access" element={<Access />} /> */}
        <Route path="customers/usergroup/add" element={<AddUserGroup />} />
        <Route path="customers/:customerId/edit" element={<AddUserGroup />} />
        <Route path="customers/:customerId/view" element={<ViewUsergroup />} />
        <Route path="customers/api/add" element={<AddApi />} />
        <Route path="customers/api/:customerId/edit" element={<AddApi />} />
        <Route path="customers/api/:customerId/view" element={<ViewApi />} />
        <Route path="customers/:customerId/licenses" element={<Licenses />} />
        <Route
          path="permissions/:permissionId/view"
          element={<ViewPermissions />}
        />
        <Route path="modules" element={<ModuelsPage />} />
        <Route path="modules/:modulesId/view" element={<ViewModules />} />
        <Route
          path="modules/visitor-management"
          element={<VisitorManagementPage />}
        />
        <Route
          path="modules/pantry-management"
          element={<PantryManagementPage />}
        />
        <Route
          path="modules/pantry-management/category-add"
          element={<CategoryAdd />}
        />
        <Route
          path="modules/pantry-management/item-add"
          element={<ItemAdd />}
        />
        <Route
          path="modules/device-management"
          element={<DeviceManagementPage />}
        />
        <Route
          path="modules/device-management/controller-add"
          element={<ControllerAdd />}
        />
        <Route
          path="profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="pod/*"
          element={
            <SuspensedView>
              <PodPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path="settings/*"
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />
        <Route path="reports" element={<ReportsWrapper />} />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
