import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export function getModulesList() {
  return axios.get<any>(`${API_URL}/modules/`);
}
export function getModuleById(mId: any) {
  return axios.get<any>(`${API_URL}/modules/${mId}/`);
}
export function getCustomersList() {
  return axios.get<any>(`${API_URL}/customers/`);
}
export function updateModule(data: any, id: string) {
  return axios.put<any>(`${API_URL}/modules/update/${id}/`, data);
}
export function deleteAccessModuleMapping(customerId: string, id: string) {
  return axios.delete<any>(`${API_URL}/modules/access/${id}/customer/${customerId}/`);
}
